.cc.BTC, 
.cc.BTC-alt {
    color: $bitcoin;
}

.cc.ETH, 
.cc.ETH-alt {
    color: $ethereum;
}

.cc.XRP, 
.cc.XRP-alt{
    color: $ripple;
}

.cc.LTC, 
.cc.LTC-alt {
    color: $litecoin;
}

.cc.NEO,
.cc.NEO-alt {
    color: $neo;
}

.cc.XMR,
.cc.XMR-alt {
    color: #FF6600;
}