// Font Variable
$pf: 'Open Sans',
sans-serif;
// Color Variable
$color-primary: #1E9379;
$color-secondary: #978DED;
$body-bg: #EAEFF3;
$sidebar-bg: #394351;
$sidebar-headerbg: #2C3645;
$heading-font-color: #2c3645;
$menu-font-color: #eeeeee;
$para-font-color-1: #847577;
$para-font-color-2: #0ab4ec;
//coins
$bitcoin: #F7931A;
$ethereum: #345281;
$ripple: #1277A8;
$litecoin: #B0B0B0;
$neo: #58BF00;
$monero: #FF6600;


$primary: #E1E1E1;
$hover: #106855;
$success: #1E9279;
$info: #F5A3E3;
$danger: #394351;
$button: #02AA49;
$warning: #FFC939;
$dark: #6a707e;
$light: #f8f9fa;
$muted: #abafb3;


///////////////
// Solid Color
///////////////
$white: #fff;
$black: #000;


////////////////////////
// Light Version Color
////////////////////////
$ctd: #454545;
$ctl: #678098;
$border: #e1e1e1;
$bg: #f3f3f3;


/////////////
// Social
/////////////
$facebook: #3B5998;
$twitter: #1DA1F2;
$youtube: #cd201f;
$google-plus: #db4439;
$linkedin: #007bb6;
$instagram: #c32aa3;
$pinterest: #bd081c;
$google: #4285f4;
$snapchat: #fffc00;
$whatsapp: #25d366;
$tumblr: #35465d;
$reddit: #ff4500;
$spotify: #1ed760;
$yahoo: #430297;
$dribbble: #ea4c89;
$skype: #00aff0;
$quora: #aa2200;
$vimeo: #1ab7ea;


////////////////
//Font Size
///////////////
$default-font-size: 1.4rem;