
// $op: 'Open Sans', sans-serif;


// Color Variable
// $blue: #090562;
// $light-blue: #0B0C21;
// $text: #637097;
// $black: #131335;
// $white: #fff; 
// $dark-white: #EBF3FC;



//
//
// reset

// body {
//     font-family: $op;
//     line-height: 1.45;
//     font-size  : 14px;
//     background: $dark-white;
// }

// a {
//     &.active,
//     &:focus,
//     &:hover {
//         text-decoration: none;
//     }
// }

// ul {
//     padding: 0;
// }

// li {
//     list-style: none;
// }

// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     font-weight: $op;
//     color      : $black;
// }

// p {
//     font-family: $op;
//     color      : $text;
//     line-height: 25px;
// }
// Global




.fix-header{
    .header{
        position: fixed;
    }
}
.fix-nav{
    .nk-sidebar{
        position: fixed;
    }
}



/////////////////
// Header
/////////////////

.header{
    background: #fff;
    width: 100%;
    z-index: 2;
    position: relative;
}

.nav-header{
    background: $sidebar-headerbg;
    color: $white;
    width: 250px;
    display: inline-block;
    text-align: center;
    .brand-logo{
        i{
            font-size: 20px;
            margin-right: 5px;
        }
        span{
            font-size: 22px;
            font-weight: 800;
            color: $white;
        }
    }
}


.header-content{
    display: inline-block;
}

.nav-control{
    margin-left: 10px;
    cursor: pointer;
    i{
        font-size: 20px;
    }
}






///////////////
// Sidebar
//////////////



.nk-sidebar {
    background: #212529;
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    padding-top: 45px;
    z-index: 1;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      background: #343a40;
    }
    .metismenu {
      background: $sidebar-bg;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      li {

        &:last-child {
          margin-bottom: 5px;
        }
      }
      > li {
        /*-webkit-box-flex: 1;
        -ms-flex: 1 1 0%;
        flex: 1 1 0%;*/
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        a{
            & > i{
                // width: 27px;
                font-size: 16px;
                display: inline-block;
                vertical-align: middle;
                padding: 0px 7px;
            }
        }
      }
      a {
        position: relative;
        display: block;
        padding: 13px 15px;
        color: #adb5bd;
        outline-width: 0;
        transition: all .3s ease-out;
        &.active {
         color: #1E9379;
        }
      }
      ul {
        a {
          padding: 10px 15px 10px 45px;
        }
        ul a {
          padding: 10px 15px 10px 60px;
        }
      }
      a {
        &:hover, &:focus, &:active {
          color: #f8f9fa;
          text-decoration: none;
          background: #0b7285;
        }
      }
    }
  }

  @media (min-width:768px) {
    .nav-mini{
        .nav-header{
       
            width: 60px;
            .brand-title{
                display: none;
            }
        }
  

        .content-body{
            margin-left: 60px;
        }
    
  
         .nk-sidebar{
       
            width: 60px;
            overflow: visible;
            .nav-text{
                display: none;
            }
            .slimScrollDiv,
            .nk-nav-scroll{
                overflow: visible!important;
            }
            .metismenu{
                 li{
                    position: relative;
                    & > ul{
                        position: absolute;
                        left: 60px;
                        top: 45px;
                        width: 190px;
                        z-index: 1001;
                        background: #f2f6f8;
                        display: none;
                        padding-left: 1px;
                    }
                }
                & > li{
                    &:hover{
                        & > a{
                            width: 250px;
                            background: #343a40;
                            .nav-text{
                                color: #fff;
                                display: inline-block;
                                padding-left: 42px;
                            }

                        }
                        & > ul{
                            display: block;
                            height: auto!important;
                            overflow: auto;
                            a {
                                padding: 10px 15px 10px 30px;
                              }
                              ul a {
                                padding: 10px 15px 10px 45px;
                              }
                        }
                    }
                }
                .has-arrow {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    }
    
    @media (max-width:767px) {

        .header{
            position: fixed;
        }
    
        .nav-header{
            width: 60px;
            .brand-title{
                display: none;
            }
        }
    
        .nav-mini{
            .nk-sidebar {
                transition: all 0.4s ease-in;
                left: -100%;
            }
        }

        .content-body{
                margin-left: 0px;
                padding-top: 65px;
        }
    
        
    }
    
    


    ////////////////////
    // Humbuger Icon
    ////////////////////

    .hamburger {
        display: inline-block;
        left: 0px;
        position: relative;
        top: 7px;
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        width: 37px;
        z-index: 999;
    }
    
    .hamburger .line {
        background-color: #252525;
        display: block;
        height: 2px;
        margin: 4px auto;
        width: 22px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    
    .hamburger:hover {
        cursor: pointer;
    }
    
    /*
    .hamburger.is-active {
      left: 65px;
    }
    */
    
    .hamburger.is-active .line:nth-child(1), .hamburger.is-active .line:nth-child(3) {
        width: 10px;
        height: 2px;
    }
    
    .hamburger.is-active .line:nth-child(2) {
        -webkit-transform: translateX(-6px);
        transform: translateX(-6px);
        width: 14px;
        height: 2px;
    }
    
    .hamburger.is-active .line:nth-child(1) {
        -webkit-transform: translateY(3px) rotate(45deg);
        transform: translateY(3px) rotate(45deg);
    }
    
    .hamburger.is-active .line:nth-child(3) {
        -webkit-transform: translateY(-3px) rotate(-45deg);
        transform: translateY(-3px) rotate(-45deg);
    }






// Variables
$bar-color: #fff;
$ball-color: #fff;
$bg-color: #EA4961;

#preloader {
  height: 100%;
  background: $bg-color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999;
}

.loader {
    position: absolute;
    width: 75px;
    height: 100px;
    top: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  
  &__bar {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 50%;
    background: $bar-color;
    transform-origin: center bottom;
    box-shadow: 1px 1px 0 rgba(0,0,0,.2);
    
    @for $i from 1 through 5 { 
       &:nth-child(#{$i}) {
         left: ($i - 1) * 15px;
         transform: scale(1,$i*.2);
         animation: barUp#{$i} 4s infinite;
        }
    }
    
  }
  
  &__ball {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: $ball-color;
    border-radius: 50%;
    animation: ball 4s infinite;
  }
}

@keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px)
  }
  17% {
    transform: translate(23px, -24px)
  }
  20% {
    transform: translate(30px, -20px)
  }
  27% {
    transform: translate(38px, -34px)
  }
  30% {
    transform: translate(45px, -30px)
  }
  37% {
    transform: translate(53px, -44px)
  }
  40% {
    transform: translate(60px, -40px)
  }
  50% {
    transform: translate(60px, 0)
  }
  57% {
    transform: translate(53px, -14px)
  }
  60% {
    transform: translate(45px, -10px)
  }
  67% {
    transform: translate(37px, -24px)
  }
  70% {
    transform: translate(30px, -20px)
  }
  77% {
    transform: translate(22px, -34px)
  }
  80% {
    transform: translate(15px, -30px)
  }
  87% {
    transform: translate(7px, -44px)
  }
  90% {
    transform: translate(0, -40px)
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes barUp1 { 
  0% {
    transform: scale(1, .2);
  }
  40%{
    transform: scale(1, .2);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(1,.2);
  }
}
@keyframes barUp2 { 
  0% {
    transform: scale(1, .4);
  }
  40% {
    transform: scale(1, .4);
  }
  50% {
    transform: scale(1, .8);
  }
  90% {
    transform: scale(1, .8);
  }
  100% {
    transform: scale(1, .4);
  }
}
@keyframes barUp3 { 
  0% {
    transform: scale(1, .6);
  }
  100% {
    transform: scale(1, .6);
  }
}
@keyframes barUp4 { 
  0% {
    transform: scale(1, .8);
  }
  40% {
    transform: scale(1, .8);
  }
  50% {
    transform: scale(1, .4);
  }
  90% {
    transform: scale(1, .4);
  }
  100% {
    transform: scale(1, .8);
  }
}
@keyframes barUp5 { 
  0% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, .2);
  }
  90% {
    transform: scale(1, .2);
  }
  100% {
    transform: scale(1, 1);
  }
}