
/////////////////
// Customization
/////////////////

.nk-sidebar {
    background-color: $sidebar-bg;

    .menu-heading {
        padding: 14px 50px;
        color: $menu-font-color;
    }
}

.nk-sidebar .metismenu a:hover, .nk-sidebar .metismenu a:focus, .nk-sidebar .metismenu a:active {
    color: $color-primary;
    text-decoration: none;
    background: $sidebar-bg;
}

.nk-sidebar .metismenu a {
    color: $menu-font-color;
    font-size: 14px;
}

.navbar-custom-menu .nav-text {
    color: $menu-font-color;
}
.hamburger {
    top: 2px !important;
}
.hamburger  {
    &.is-active {
        top: 5px !important;
    }
}
// @media (min-width: 768px)
.nav-mini .nk-sidebar .metismenu li > ul {
    background: $sidebar-bg;
}
.navbar-custom-menu {
    height: 50px;
    align-items: center;
}
.nav-right  {
    padding: 0 20px;

    @include respond('phone') {
        padding: 0 10px;
    }

    .dropdown-item {
        max-width: 100%;
    }

    .navbar-nav {
        flex-direction: row;
    }

    .dropdown-menu {
        border-radius: 0;
        border: none;

        a.dropdown-item {
            color: $para-font-color-1;
            width: 170px;
            padding: 9px 31px;

            @include respond('phone') {
                padding: 9px 5px;
            }

            .media {
                max-width: 100%;
                overflow: hidden;

                h6 {
                    position: relative;
                    font-size: 14px;
                    font-weight: bold;
                    color: $heading-font-color;

                    span.time {
                        position: absolute;
                        right: 67px;
                        font-weight: normal;
                        color: $para-font-color-1;

                        @include respond('phone') {
                            right: 125px;
                        }
                    }
                }

                .media-body {
                    display: inline-block;
                    white-space: nowrap;
                }
            }

            &:hover {
                color: $heading-font-color;
            }
        }
    }

    .dropdown-menu.inbox-dropdown {
        width: 360px;
        max-height: 300px;
        overflow-x: hidden;

        @include respond('phone') {
            width: 270px;
        }

        .dropdown-item:not(:last-child) {
            border-bottom: 1px solid #eeeeee;
        }

        & >a {
            width: 100%;
            max-width: 100%;
            display: block;
            overflow: hidden;
        }

        .message-options {
            border-bottom: 1px solid #EEEEEE;
            padding: 5px 15px;

            a {
                color: $heading-font-color;

                &:hover {
                    text-decoration: underline;
                }
            }

            a.pull-right {
                margin-left: 20px;

                @include respond('phone') {
                    margin-left: 10px;
                }
            }
        }
    }
    
    .dropdown i {
        color: #847577;
    }

    .user-profile-dropdown.dropdown-toggle {
        padding-top: 2px;
    
        .user-img {
            margin-right: 10px;
        }

        .user-name {
            @include respond('tab') {
                display: none;
            }
        }
    }

    .dropdown-toggle {
        display: inline-block;
        padding: 0 18px;
        font-size: 14px;
        color: $heading-font-color;
        position: relative;

        @include respond('tab') {
            padding: 3px 8px;
        }

        .badge {
            position: absolute;
            right: 9px;
            top: 10px;
            height: 9px;

            @include respond('tab') {
                right: 5px;
            }
        }
    }

    .dropdown-toggle::after {
        display: none;
    }
}



.nav-search-box {
    width: 450px;
    position: relative;
    margin-left: 15px;

    @include media('993px', '1087px') {
        width: 300px;
    }

    @include respond('tab') {
        width: 300px;
    }

    @include respond('phone') {
        display: none;
    }

    i {
        position: absolute;
        top: 5px;
        left: 15px;
        font-size: 18px;
    }

    input[type="search"] {
        width: 100%;
        border: none;
        padding-left: 45px;
        padding-top: 3px;
    }
}

.notification-dropdown {
    width: 280px;
    padding: 0 0 0 0;
    margin: 0;

    @include respond('phone') {
        width: 240px;
    }

    .header {
        border-bottom: 1px solid #f4f4f4;
        padding: 7px 10px;
    }

    .footer {

        a {
            padding: 7px 10px;
            border-bottom: 1px solid #eeeeee;
            text-align: center;
            display: block;
            clear: both;
            line-height: 1.42857143;
            white-space: nowrap;
            color: $para-font-color-1;
        }
    }

    .menu {
        max-height: 200px;
        margin: 0;
        padding: 0;
        list-style: none;
        overflow-x: hidden;

        a {
            color: #444444;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 10px;
            display: block;
            white-space: nowrap;
            border-bottom: 1px solid #f4f4f4;
        }
    }
}

.nav-header .brand-logo {
    display: flex;
    align-items: center;
    justify-content: center;   
    padding: 10px 0;
}
.nav-header {
    @include respond('tab') {
        width: 60px;
    }
}

.nav-header .brand-title {
    @include respond('tab') {
        display: none;
    }
}

.nav-control {

    @include respond('phone') {
        margin: 0;
    }
}

.header {
    z-index: 100;
}

.nk-sidebar {
    z-index: 99;
}
.nk-sidebar .simplebar-mask {
    overflow: inherit;
}

