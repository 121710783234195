@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    } 
}

@mixin centerElement{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}







//MEDIA QUERY MANAGER

/*
0 - 768: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop

1em = 16px

The smaller device rules always should write below the bigger device rules

Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/

@mixin respond($breakpoint) {

	@if($breakpoint == "phone"){ 

		@media only screen and (max-width: 768px) {@content;} 
	}

	@if($breakpoint == "tab"){

		@media only screen and (max-width: 992px) {@content;}
	}

	@if($breakpoint == "desktop"){

		@media only screen and (max-width: 1200px) {@content;} 
	}
	
	@if($breakpoint == "big-desktop"){

		@media only screen and (min-width: 112.5em) {@content;} //1800px
	}

}

@mixin media($min, $max) {
    @media only screen and (min-width: $min) and (max-width: $max) {@content;} 
}