.currency-hero-area {
    padding: 100px 0;
    text-align: center;
    color: $white;
    background-size: cover;
    background-position: center;
    margin-bottom: 25px;

    .exchange-input {
        margin: 45px 0;
        display: flex;

        .input-group:first-child {

            @include respond('phone') {
                margin-bottom: 0 !important;
            }
        }

        @include respond('phone') {
            display: block;
        }
    }

    .exchange-icon {
        padding: 5px 15px 0;
    }

    button {
        background-color: #18212C;
    }

    h1 {
        color: $white;
        margin-bottom: 45px;
    }
}
.exchange-input {
    input[type="text"] {
        min-height: 40px;
    }
}